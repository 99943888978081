import { template as template_a094d2185e8c43a19a698a3c67bd8e02 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a094d2185e8c43a19a698a3c67bd8e02(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
