import { template as template_fe59c0958ec6404cbe538ed568028160 } from "@ember/template-compiler";
const FKLabel = template_fe59c0958ec6404cbe538ed568028160(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
